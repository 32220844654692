import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { createStackNavigator } from "@react-navigation/stack";
import { featureList, useFeature } from "@src/lib/FeatureFlags";
import TermsAndConditions from "@src/screens/Tenant/TermsAndConditions/TermsAndConditions";
import { register } from "react-native-bundle-splitter";
import { HeaderNavigation } from "./HeaderNavigation";
import { PaymentSettingsNavigator } from "./PaymentSettingsNavigator/PaymentSettingsNavigator";
import { RequestsNavigator } from "./TenancyNavigator/RequestsNavigator";
import { GlobalTenantProvider } from "./hooks/useGlobalTenant";
import { BrandingProvider } from "./useBranding";
import GroupStatements from "@src/screens/Owner/Statements/GroupStatements";
import { useAuth } from "react-oidc-context";
import { MyKolmeoLoader } from "@src/components";

export type RootStackParamList = {
  RootScreen: undefined;
  Logout: undefined;
  AuthCallback: undefined;
  MyProperties: undefined;
  Property: { propertyId: number };
  Tenancy?: { tenancyId: number };
  Requests: { tenancyId?: number };
  PaymentSettings: { tenancyId?: number };
  TAC: undefined;
  Welcome: undefined;
  GroupStatements: { propertyId: number };
};

export const RootStack = createStackNavigator<RootStackParamList>();

export type NavParams = NativeStackScreenProps<RootStackParamList>;
export type RootScreenParamList = NativeStackScreenProps<RootStackParamList, "RootScreen">;
export type MyPropertiesParamList = NativeStackScreenProps<RootStackParamList, "MyProperties">;
export type PropertyParamList = NativeStackScreenProps<RootStackParamList, "Property">;
export type TenancyParamList = NativeStackScreenProps<RootStackParamList, "Tenancy">;
export type RequestsParamList = NativeStackScreenProps<RootStackParamList, "Requests">;
export type PaymentsParamList = NativeStackScreenProps<RootStackParamList, "PaymentSettings">;
export type Welcome = NativeStackScreenProps<RootStackParamList, "Welcome">;

const RootStackNavigator = (): JSX.Element => {
  const showTenants = useFeature(featureList.showTenants);
  const { isLoading } = useAuth();

  if (isLoading) return <MyKolmeoLoader />;

  return (
    <GlobalTenantProvider>
      <BrandingProvider>
        <RootStack.Navigator screenOptions={{ header: (props) => <HeaderNavigation {...props} /> }} initialRouteName="RootScreen">
          <RootStack.Screen
            name="RootScreen"
            component={register({ loader: () => import("@src/screens/RootScreen") })}
            options={{ title: "My Kolmeo", headerShown: false }}
          />
          <RootStack.Screen
            name="Welcome"
            component={register({ loader: () => import("@src/screens/Welcome/WelcomeScreen") })}
            options={{ title: "Welcome", headerShown: false }}
          />
          <RootStack.Screen
            name="AuthCallback"
            component={register({ loader: () => import("@src/screens/AuthCallback/AuthCallbackRedirectOnly") })}
            options={{ title: "My Kolmeo", headerShown: false }}
          />
          <RootStack.Screen
            name="Logout"
            component={register({ loader: () => import("@src/screens/AuthCallback/Logout") })}
            options={{ title: "My Kolmeo", headerShown: false }}
          />

          <RootStack.Screen
            name="MyProperties"
            component={register({ loader: () => import("@src/screens/Owner/MyProperties") })}
            options={{ title: "My Properties" }}
          />
          <RootStack.Screen name="Property" component={register({ loader: () => import("./PropertyNavigator") })} />
          <RootStack.Screen
            name="Tenancy"
            component={
              showTenants?.enabled
                ? register({ loader: () => import("@src/navigation/TenancyNavigator") })
                : register({ loader: () => import("@src/screens/Fallback") })
            }
          />
          <RootStack.Screen
            name="Requests"
            options={{ title: "Requests" }}
            component={showTenants?.enabled ? RequestsNavigator : register({ loader: () => import("@src/screens/Fallback") })}
          />
          <RootStack.Screen
            name="PaymentSettings"
            options={{ title: "Payment Settings" }}
            component={showTenants?.enabled ? PaymentSettingsNavigator : register({ loader: () => import("@src/screens/Fallback") })}
          />
          <RootStack.Screen
            name="TAC"
            options={{ title: "Terms and Conditions" }}
            component={showTenants?.enabled ? TermsAndConditions : register({ loader: () => import("@src/screens/Fallback") })}
          />
          <RootStack.Screen
            name="GroupStatements"
            options={{ title: "Statements" }}
            component={showTenants?.enabled ? GroupStatements : register({ loader: () => import("@src/screens/Fallback") })}
          />
        </RootStack.Navigator>
      </BrandingProvider>
    </GlobalTenantProvider>
  );
};
export type Routes = keyof typeof RootStackNavigator;

export default RootStackNavigator;
