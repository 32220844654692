import { useQuery } from "@tanstack/react-query";

import { useAxios } from "@src/lib/http/useAxios";
import {
  ExtendedGetTenancyDetailsResponse,
  GetAllTenanciesResponse,
  GetTenancyDetailsResponse
} from "@src/navigation/TenancyNavigator/types";
import {
  calculateApplicableFrequency,
  calculateApplicableRentAmount,
  calculateHasAdvancedPayment,
  calculateIsArrears,
  calculateIsArrearsMultipleCycles,
  calculateIsPaidToActualAdjustedByPartPayment,
  calculateOutstandingAmount,
  calculateOverdueArrearsAmount,
  calculateTenantHasNothingMoreToPay,
  checkTenantLeaseStatus,
  isAddRentAmountIntoArrearsAmount as calculateIsAddRentAmountIntoArrearsAmount,
  calculateIsNextRentApplicable,
  calculateIsNoCurrentRentRecord,
  calculateHasNoRentRecords
} from "./calculations";
import { getRentAmountFrequency } from "@src/hooks";

export const TENANCY_DETAILS_QUERY_KEY = "tenancy-details";

export const useGetAllTenancies = () => {
  const { instance, isEnabled } = useAxios("TENANT_API");

  return useQuery<GetAllTenanciesResponse[]>({
    queryKey: ["tenancy"],
    queryFn: async () => {
      const response = await instance.get("/tenancy");
      return response.data;
    },
    enabled: isEnabled
  });
};

export const useGetTenancyDetails = (tenancyId?: number) => {
  const { instance } = useAxios("TENANT_API");

  return useQuery<ExtendedGetTenancyDetailsResponse>({
    queryKey: [TENANCY_DETAILS_QUERY_KEY, tenancyId],
    queryFn: async () => {
      const response = await instance.get(`/tenancy/details/${tenancyId}`);

      const data = response.data as GetTenancyDetailsResponse;

      const isPaidToActualAdjustedByPartPayment = calculateIsPaidToActualAdjustedByPartPayment(data);
      const applicableRentAmount = calculateApplicableRentAmount(data);
      const applicableFrequency = calculateApplicableFrequency(data);
      const isNextRentApplicable = calculateIsNextRentApplicable(data);
      const outstandingAmount = calculateOutstandingAmount(data);
      const overdueArrearsAmount = calculateOverdueArrearsAmount(data);
      const isInArrears = calculateIsArrears(data);
      const isArrearsMultipleCycles = calculateIsArrearsMultipleCycles(data);
      const hasAdvancedPayment = calculateHasAdvancedPayment(data);
      const applicableRentAmountFrequency = getRentAmountFrequency(applicableRentAmount, applicableFrequency);
      const isNoCurrentRentRecord = calculateIsNoCurrentRentRecord(data);
      const hasNoRentRecords = calculateHasNoRentRecords(data);

      const tenantLeaseStatus = checkTenantLeaseStatus(data);
      const tenantHasNothingMoreToPay = calculateTenantHasNothingMoreToPay(data);

      const isAddRentAmountIntoArrearsAmount = calculateIsAddRentAmountIntoArrearsAmount(data);

      return {
        ...data,
        applicableRentAmount,
        applicableFrequency,
        isNextRentApplicable,
        applicableRentAmountFrequency,
        outstandingAmount,
        overdueArrearsAmount,
        isInArrears,
        isPaidToActualAdjustedByPartPayment,
        hasAdvancedPayment,
        isArrearsMultipleCycles,
        tenantLeaseStatus,
        tenantHasNothingMoreToPay,
        isAddRentAmountIntoArrearsAmount,
        isNoCurrentRentRecord,
        hasNoRentRecords
      };
    },
    enabled: !!tenancyId
  });
};
