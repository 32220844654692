import ApplicationStack from "./ApplicationStack";
import "./i18n";
import PendoHelmet from "@src/lib/Analytics/Pendo/PendoHelmet";

const REACT_APP_Pendo_App_Id = process.env.REACT_APP_Pendo_App_Id;

const App = () => {
  return (
    <>
      {REACT_APP_Pendo_App_Id && <PendoHelmet pendoAppId={REACT_APP_Pendo_App_Id} />}
      <ApplicationStack />
    </>
  );
};

export default App;
