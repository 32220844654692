import { rest } from "msw";
import { endpoints } from "@src/lib/http/useEndpointUrls";
import { GetAllTenanciesResponse } from "@src/navigation/TenancyNavigator/types";
import { OccupancyStatus } from "@src/types";

export const getAllTenanciesMock: GetAllTenanciesResponse[] = [
  {
    propertyId: 53208,
    propertyEntityId: "0c5f2be0-584d-4c85-bda3-b88872462606",
    propertyName: "1A Connect Avenue Street, Melbourne, VIC, 3000",
    tenancyId: 68994,
    tenancyEntityId: "95b8a01a-6ef7-4fb2-969e-11818120e6bd",
    status: OccupancyStatus.active
  },
  {
    propertyId: 53201,
    propertyEntityId: "0c5f2be0-584d-4c85-bda3-b88872462606",
    propertyName: "24 Inactive Avenue Street, Melbourne, VIC, 3000",
    tenancyId: 72322,
    tenancyEntityId: "357352ec-4587-43ac-9811-ea1ef7d335c6",
    status: OccupancyStatus.inactive
  },
  {
    propertyId: 53204,
    propertyEntityId: "0c5f2be0-584d-4c85-bda3-b88872462606",
    propertyName: "100 Direct Debit Lane, Melbourne, VIC, 3000",
    tenancyId: 72328,
    tenancyEntityId: "357352ec-4587-43ac-9811-ea1ef7d335c6",
    status: OccupancyStatus.leaseSigned
  },
  {
    propertyId: 53202,
    propertyEntityId: "0c5f2be0-584d-4c85-bda3-b88872462606",
    propertyName: "5 Applied Rd, Melbourne, VIC, 3333",
    tenancyId: 72388,
    tenancyEntityId: "357352ec-4587-43ac-9811-ea1ef7d335c6",
    status: OccupancyStatus.applied
  }
];

export const getAllTenanciesHandler = rest.get(`${endpoints.TENANT_API}/tenancy`, (_, res, ctx) => {
  return res(ctx.status(200), ctx.json(getAllTenanciesMock));
});
