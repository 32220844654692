import { Box, Text, TouchableOpacity, useTheme, ScrollView } from "@kolmeo/ui-core";
import { MaterialTopTabBarProps, MaterialTopTabNavigationOptions } from "@react-navigation/material-top-tabs";
import { useMediaQuery } from "@src/hooks";
import { addAlphaToHex } from "@src/lib/Kiitos";

const TabBarItem = ({
  isFocused,
  options,
  onPress,
  onLongPress,
  routeName,
  indicatorColour
}: {
  isFocused: boolean;
  options: MaterialTopTabNavigationOptions;
  onPress: () => void;
  onLongPress: () => void;
  routeName: string;
  indicatorColour?: string;
}) => {
  const createLabel = (options: MaterialTopTabNavigationOptions): string => {
    const { tabBarLabel, title } = options;
    if (tabBarLabel) {
      return tabBarLabel as string;
    }
    if (title) {
      return title;
    }
    return routeName;
  };

  const { colors, palette } = useTheme();

  const color = indicatorColour ?? colors.primary;

  return (
    <TouchableOpacity
      accessibilityRole="button"
      accessibilityState={isFocused ? { selected: true } : {}}
      accessibilityLabel={options.tabBarAccessibilityLabel}
      testID={options.tabBarTestID}
      onPress={onPress}
      onLongPress={onLongPress}
    >
      <Box paddingY={"16px"} paddingX={"8px"} flexDirection="row" alignItems="center">
        {options.tabBarIcon && (
          <>
            {options.tabBarIcon({ focused: isFocused, color: isFocused ? color : addAlphaToHex(color) })}
            <Box width="8px" height="100%" />
          </>
        )}

        <Text color={isFocused ? palette.text.primary : palette.background.disable} variant="textHeavy14">
          {createLabel(options)}
        </Text>
      </Box>
      {isFocused && <Box backgroundColor={color} height="3px" borderRadius="1.5px" width="100%" />}
    </TouchableOpacity>
  );
};
const CustomTabBar = ({ state, descriptors, navigation, indicatorColour }: MaterialTopTabBarProps & { indicatorColour?: string }) => {
  const { isMobile } = useMediaQuery();
  return (
    <Box width="100%" backgroundColor="white">
      <Box maxWidth="1280px" width="100%" margin="0 auto" flexDirection="row" paddingX={isMobile ? "16px" : undefined}>
        <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
          <Box alignItems="flex-start" flexDirection="row">
            {state.routes.map((route, index) => {
              const { options } = descriptors[route.key];

              const isFocused = state.index === index;

              const onPress = () => {
                const event = navigation.emit({
                  type: "tabPress",
                  target: route.key,
                  canPreventDefault: true
                });

                if (!isFocused && !event.defaultPrevented) {
                  navigation.navigate({ name: route.name, merge: true, params: {} });
                }
              };

              const onLongPress = () => {
                navigation.emit({
                  type: "tabLongPress",
                  target: route.key
                });
              };

              return (
                <TabBarItem
                  key={`${route.name}_${index}`}
                  isFocused={isFocused}
                  onPress={onPress}
                  onLongPress={onLongPress}
                  options={options}
                  routeName={route.name}
                  indicatorColour={indicatorColour}
                />
              );
            })}
          </Box>
        </ScrollView>
      </Box>
    </Box>
  );
};

export default CustomTabBar;
