import EntryInspection from "./EntryInspection";
import { default as EntryInspectionProps } from "./EntryInspection/svgData";
import RoutineInspection from "./RoutineInspection";
import { default as RoutineInspectionProps } from "./RoutineInspection/svgData";
import ExitInspection from "./ExitInspection";
import { default as ExitInspectionProps } from "./ExitInspection/svgData";
import Close from "./Close";
import { default as CloseProps } from "./Close/svgData";
import Magnify from "./Magnify";
import { default as MagnifyProps } from "./Magnify/svgData";
import ChevronDown from "./ChevronDown";
import { default as ChevronDownProps } from "./ChevronDown/svgData";
import Check from "./Check";
import { default as CheckProps } from "./Check/svgData";
import AccountAlert from "./AccountAlert";
import { default as AccountAlertProps } from "./AccountAlert/svgData";
import ChevronRight from "./ChevronRight";
import { default as ChevronRightProps } from "./ChevronRight/svgData";
import AccountClock from "./AccountClock";
import { default as AccountClockProps } from "./AccountClock/svgData";
import Avatar from "./Avatar";
import { default as AvatarProps } from "./Avatar/svgData";
import Eye from "./Eye";
import { default as EyeProps } from "./Eye/svgData";
import ClockOutline from "./ClockOutline";
import { default as ClockOutlineProps } from "./ClockOutline/svgData";
import Map from "./Map";
import { default as MapProps } from "./Map/svgData";
import Minus from "./Minus";
import { default as MinusProps } from "./Minus/svgData";
import FileDocument from "./FileDocument";
import { default as FileDocumentProps } from "./FileDocument/svgData";
import Camera from "./Camera";
import { default as CameraProps } from "./Camera/svgData";
import CalendarBlank from "./CalendarBlank";
import { default as CalendarBlankProps } from "./CalendarBlank/svgData";
import CalendarBlankFill from "./CalendarBlankFill";
import { default as CalendarBlankFillProps } from "./CalendarBlankFill/svgData";
import Drag from "./Drag";
import { default as DragProps } from "./Drag/svgData";
import ChevronUp from "./ChevronUp";
import { default as ChevronUpProps } from "./ChevronUp/svgData";
import FolderKey from "./FolderKey";
import { default as FolderKeyProps } from "./FolderKey/svgData";
import Information from "./Information";
import { default as InformationProps } from "./Information/svgData";
import Phone from "./Phone";
import { default as PhoneProps } from "./Phone/svgData";
import CheckCircle from "./CheckCircle";
import { default as CheckCircleProps } from "./CheckCircle/svgData";
import BlockHelper from "./BlockHelper";
import { default as BlockHelperProps } from "./BlockHelper/svgData";
import Sync from "./Sync";
import { default as SyncProps } from "./Sync/svgData";
import ProgressClock from "./ProgressClock";
import { default as ProgressClockProps } from "./ProgressClock/svgData";
import ArrowLeft from "./ArrowLeft";
import { default as ArrowLeftProps } from "./ArrowLeft/svgData";
import HomeAccount from "./HomeAccount";
import { default as HomeAccountProps } from "./HomeAccount/svgData";
import CommentTextMultiple from "./CommentTextMultiple";
import { default as CommentTextMultipleProps } from "./CommentTextMultiple/svgData";
import WifiOff from "./WifiOff";
import { default as WifiOffProps } from "./WifiOff/svgData";
import Error from "./Error";
import { default as ErrorProps } from "./Error/svgData";
import Pets from "./Pets";
import { default as PetsProps } from "./Pets/svgData";
import Flag from "./Flag";
import { default as FlagProps } from "./Flag/svgData";
import Star from "./Star";
import { default as StarProps } from "./Star/svgData";
import ShapeCirclePlus from "./ShapeCirclePlus";
import { default as ShapeCirclePlusProps } from "./ShapeCirclePlus/svgData";
import CircleOutline from "./CircleOutline";
import { default as CircleOutlineProps } from "./CircleOutline/svgData";
import AlertCircle from "./AlertCircle";
import { default as AlertCircleProps } from "./AlertCircle/svgData";
import AlertCircleOutline from "./AlertCircleOutline";
import { default as AlertCircleOutlineProps } from "./AlertCircleOutline/svgData";
import CheckCircleOutline from "./CheckCircleOutline";
import { default as CheckCircleOutlineProps } from "./CheckCircleOutline/svgData";
import EllipsisV from "./EllipsisV";
import { default as EllipsisVProps } from "./EllipsisV/svgData";
import CaretDown from "./CaretDown";
import { default as CaretDownProps } from "./CaretDown/svgData";
import Comment from "./Comment";
import { default as CommentProps } from "./Comment/svgData";
import Key from "./Key";
import { default as KeyProps } from "./Key/svgData";
import Cancel from "./Cancel";
import { default as CancelProps } from "./Cancel/svgData";
import Plus from "./Plus";
import { default as PlusProps } from "./Plus/svgData";
import CalendarEdit from "./CalendarEdit";
import { default as CalendarEditProps } from "./CalendarEdit/svgData";
import Calendar from "./Calendar";
import { default as CalendarProps } from "./Calendar/svgData";
import List from "./List";
import { default as ListProps } from "./List/svgData";
import Forward from "./Forward";
import { default as ForwardProps } from "./Forward/svgData";
import DeleteForever from "./DeleteForever";
import { default as DeleteForeverProps } from "./DeleteForever/svgData";
import ChevronLeft from "./ChevronLeft";
import { default as ChevronLeftProps } from "./ChevronLeft/svgData";
import Loading from "./Loading";
import { default as LoadingProps } from "./Loading/svgData";
import HomeImportOutline from "./HomeImportOutline";
import { default as HomeImportOutlineProps } from "./HomeImportOutline/svgData";
import SquareEditOutline from "./SquareEditOutline";
import { default as SquareEditOutlineProps } from "./SquareEditOutline/svgData";
import Upload from "./Upload";
import { default as UploadProps } from "./Upload/svgData";
import PhotoLibrary from "./PhotoLibrary";
import { default as PhotoLibraryProps } from "./PhotoLibrary/svgData";
import MapMarker from "./MapMarker";
import { default as MapMarkerProps } from "./MapMarker/svgData";
import CashMultiple from "./CashMultiple";
import { default as CashMultipleProps } from "./CashMultiple/svgData";
import Wrench from "./Wrench";
import { default as WrenchProps } from "./Wrench/svgData";
import Cog from "./Cog";
import { default as CogProps } from "./Cog/svgData";
import CloseCircle from "./CloseCircle";
import { default as CloseCircleProps } from "./CloseCircle/svgData";
import FilterVariant from "./FilterVariant";
import { default as FilterVariantProps } from "./FilterVariant/svgData";
import Pending from "./Pending";
import { default as PendingProps } from "./Pending/svgData";
import SadFaceOutline from "./SadFaceOutline";
import { default as SadFaceOutlineProps } from "./SadFaceOutline/svgData";
import CheckBoxBlankSquare from "./CheckBoxBlankSquare";
import { default as CheckBoxBlankSquareProps } from "./CheckBoxBlankSquare/svgData";
import CheckBoxSquare from "./CheckBoxSquare";
import { default as CheckBoxSquareProps } from "./CheckBoxSquare/svgData";
import SinglePhoto from "./SinglePhoto";
import { default as SinglePhotoProps } from "./SinglePhoto/svgData";
import LongArrowBack from "./LongArrowBack";
import { default as LongArrowBackProps } from "./LongArrowBack/svgData";
import InversedArrows from "./InversedArrows";
import { default as InversedArrowsProps } from "./InversedArrows/svgData";
import Download from "./Download";
import { default as DownloadProps } from "./Download/svgData";
import Pencil from "./Pencil";
import { default as PencilProps } from "./Pencil/svgData";
import Menu from "./Menu";
import { default as MenuProps } from "./Menu/svgData";
import GridDrag from "./GridDrag";
import { default as GridDragProps } from "./GridDrag/svgData";
import Email from "./Email";
import { default as EmailProps } from "./Email/svgData";
import AutoRenew from "./AutoRenew";
import { default as AutoRenewProps } from "./AutoRenew/svgData";
import ChatTextFill from "./ChatTextFill";
import { default as ChatTextFillProps } from "./ChatTextFill/svgData";
import ImageSquareFill from "./ImageSquareFill";
import { default as ImageSquareFillProps } from "./ImageSquareFill/svgData";
import MinusCircleFill from "./MinusCircleFill";
import { default as MinusCircleFillProps } from "./MinusCircleFill/svgData";
import PlusCircleFill from "./PlusCircleFill";
import { default as PlusCircleFillProps } from "./PlusCircleFill/svgData";
import ArrowsInLineVerticalFill from "./ArrowsInLineVerticalFill";
import { default as ArrowsInLineVerticalFillProps } from "./ArrowsInLineVerticalFill/svgData";
import ArrowsOutLineVerticalFill from "./ArrowsOutLineVerticalFill";
import { default as ArrowsOutLineVerticalFillProps } from "./ArrowsOutLineVerticalFill/svgData";
import TrashFill from "./TrashFill";
import { default as TrashFillProps } from "./ArrowsInLineVerticalFill/svgData";
import CaretLeft from "./CaretLeft";
import { default as CaretLeftProps } from "./CaretLeft/svgData";
import Archive from "./Archive";
import { default as ArchiveProps } from "./Archive/svgData";
import DotsThreeOutline from "./DotsThreeOutline";
import { default as DotsThreeOutlineProps } from "./DotsThreeOutline/svgData";
import ChatsOutline from "./ChatsOutline";
import { default as ChatsOutlineProps } from "./ChatsOutline/svgData";
import Chats from "./Chats";
import { default as ChatsProps } from "./Chats/svgData";
import ClipboardOutline from "./ClipboardOutline";
import { default as ClipboardOutlineProps } from "./ClipboardOutline/svgData";
import Clipboard from "./Clipboard";
import { default as ClipboardProps } from "./Clipboard/svgData";
import Home from "./Home";
import { default as HomeProps } from "./Home/svgData";
import HomeOutline from "./HomeOutline";
import { default as HomeOutlineProps } from "./HomeOutline/svgData";
import CaretRight from "./CaretRight";
import { default as CaretRightProps } from "./CaretRight/svgData";
import Bedrooms from "./Bedrooms";
import { default as BedroomsProps } from "./Bedrooms/svgData";
import Bathrooms from "./Bathrooms";
import { default as BathroomsProps } from "./Bathrooms/svgData";
import Carparks from "./Carparks";
import { default as CarparksProps } from "./Carparks/svgData";
import DollarSign from "./DollarSign";
import { default as DollarSignProps } from "./DollarSign/svgData";
import WarningCircle from "./WarningCircle";
import { default as WarningCircleProps } from "./WarningCircle/svgData";
import ListDashes from "./ListDashes";
import { default as ListDashesProps } from "./ListDashes/svgData";
import Hourglass from "./Hourglass";
import { default as HourglassProps } from "./Hourglass/svgData";
import HourglassMedium from "./HourglassMedium";
import { default as HourglassMediumProps } from "./HourglassMedium/svgData";
import CheckFill from "./CheckFill";
import { default as CheckFillProps } from "./CheckFill/svgData";
import NoEye from "./NoEye";
import { default as NoEyeProps } from "./NoEye/svgData";
import ArrowSquareOut from "./ArrowSquareOut";
import { default as ArrowSquareOutProps } from "./ArrowSquareOut/svgData";
import ChatCircle from "./ChatCircle";
import { default as ChatCircleProps } from "./ChatCircle/svgData";
import CheckCircleFill from "./CheckCircleFill";
import { default as CheckCircleFillProps } from "./CheckCircleFill/svgData";
import ClipboardTextFill from "./ClipboardTextFill";
import { default as ClipboardTextFillProps } from "./ClipboardTextFill/svgData";
import XCircleFill from "./XCircleFill";
import { default as XCircleFillProps } from "./XCircleFill/svgData";
import ChecksFill from "./ChecksFill";
import { default as ChecksFillProps } from "./ChecksFill/svgData";
import RequestsActive from "./RequestsActive";
import { default as RequestsActiveProps } from "./RequestsActive/svgData";
import RequestsDefault from "./RequestsDefault";
import { default as RequestsDefaultProps } from "./RequestsDefault/svgData";
import PropertyActive from "./PropertyActive";
import { default as PropertyActiveProps } from "./PropertyActive/svgData";
import PropertyDefault from "./PropertyDefault";
import { default as PropertyDefaultProps } from "./PropertyDefault/svgData";
import PaymentsActive from "./PaymentsActive";
import { default as PaymentsActiveProps } from "./PaymentsActive/svgData";
import PaymentsDefault from "./PaymentsDefault";
import { default as PaymentsDefaultProps } from "./PaymentsDefault/svgData";
import StatementsActive from "./StatementsActive";
import { default as StatementsActiveProps } from "./StatementsActive/svgData";
import StatementsDefault from "./StatementsDefault";
import { default as StatementsDefaultProps } from "./StatementsDefault/svgData";
import GenericFile from "./FileIconVariations/GenericFile";
import { default as GenericFileProps } from "./FileIconVariations/GenericFile/svgData";
import PdfFile from "./FileIconVariations/PdfFile";
import { default as PdfFileProps } from "./FileIconVariations/PdfFile/svgData";
import Bank from "./Bank";
import { default as BankProps } from "./Bank/svgData";
import Signout from "./Signout";
import { default as SignoutProps } from "./Bank/svgData";
import HandClick from "./HandClick";
import { default as HandClickProps } from "./HandClick/svgData";
import BPAY from "./BPAY";
import { default as BPAYProps } from "./BPAY/svgData";
import Copy from "./Copy";
import { default as CopyProps } from "./Copy/svgData";
import DoorOpen from "./DoorOpen";
import { default as DoorOpenProps } from "./DoorOpen/svgData";
const Icons = {
    "requests-active": RequestsActive,
    "requests-default": RequestsDefault,
    "property-active": PropertyActive,
    "property-default": PropertyDefault,
    "payments-active": PaymentsActive,
    "payments-default": PaymentsDefault,
    "statements-active": StatementsActive,
    "statements-default": StatementsDefault,
    "entry-inspection": EntryInspection,
    "routine-inspection": RoutineInspection,
    "exit-inspection": ExitInspection,
    close: Close,
    "close-circle": CloseCircle,
    magnify: Magnify,
    "chevron-down": ChevronDown,
    check: Check,
    "check-circle": CheckCircle,
    "check-circle-outline": CheckCircleOutline,
    "check-box-blank-square": CheckBoxBlankSquare,
    "check-box-square": CheckBoxSquare,
    "account-alert": AccountAlert,
    "chevron-right": ChevronRight,
    "account-clock": AccountClock,
    eye: Eye,
    "clock-outline": ClockOutline,
    map: Map,
    minus: Minus,
    "file-document": FileDocument,
    camera: Camera,
    calendar: Calendar,
    "calendar-blank": CalendarBlank,
    "calendar-blank-fill": CalendarBlankFill,
    "calendar-edit": CalendarEdit,
    drag: Drag,
    "chevron-up": ChevronUp,
    "folder-key": FolderKey,
    information: Information,
    phone: Phone,
    "block-helper": BlockHelper,
    sync: Sync,
    "progress-clock": ProgressClock,
    "arrow-left": ArrowLeft,
    "home-account": HomeAccount,
    "comment-text-multiple": CommentTextMultiple,
    "wifi-off": WifiOff,
    error: Error,
    pets: Pets,
    flag: Flag,
    star: Star,
    "shape-circle-plus": ShapeCirclePlus,
    "circle-outline": CircleOutline,
    "alert-circle": AlertCircle,
    "alert-circle-outline": AlertCircleOutline,
    "ellipsis-v": EllipsisV,
    "caret-down": CaretDown,
    comment: Comment,
    key: Key,
    cancel: Cancel,
    pencil: Pencil,
    plus: Plus,
    list: List,
    forward: Forward,
    "delete-forever": DeleteForever,
    "chevron-left": ChevronLeft,
    loading: Loading,
    "home-import-outline": HomeImportOutline,
    "square-edit-outline": SquareEditOutline,
    upload: Upload,
    download: Download,
    "photo-library": PhotoLibrary,
    "map-marker": MapMarker,
    "cash-multiple": CashMultiple,
    wrench: Wrench,
    cog: Cog,
    "filter-variant": FilterVariant,
    pending: Pending,
    "sad-face-outline": SadFaceOutline,
    "single-photo": SinglePhoto,
    "long-arrow-back": LongArrowBack,
    "inversed-arrows": InversedArrows,
    menu: Menu,
    "grid-drag": GridDrag,
    email: Email,
    "auto-renew": AutoRenew,
    "chat-text-fill": ChatTextFill,
    "image-square-fill": ImageSquareFill,
    "minus-circle-fill": MinusCircleFill,
    "plus-circle-fill": PlusCircleFill,
    "arrows-in-line-vertical-fill": ArrowsInLineVerticalFill,
    "arrows-out-line-vertical-fill": ArrowsOutLineVerticalFill,
    "trash-fill": TrashFill,
    "caret-left": CaretLeft,
    archive: Archive,
    "dots-three-outline": DotsThreeOutline,
    "chats-outline": ChatsOutline,
    chats: Chats,
    "clipboard-outline": ClipboardOutline,
    clipboard: Clipboard,
    home: Home,
    "home-outline": HomeOutline,
    "caret-right": CaretRight,
    "warning-circle": WarningCircle,
    avatar: Avatar,
    bedrooms: Bedrooms,
    bathrooms: Bathrooms,
    carparks: Carparks,
    "dollar-sign": DollarSign,
    "list-dashes": ListDashes,
    "check-fill": CheckFill,
    hourglass: Hourglass,
    "hourglass-medium": HourglassMedium,
    "no-eye": NoEye,
    "arrow-square-out": ArrowSquareOut,
    "chat-circle": ChatCircle,
    "check-circle-fill": CheckCircleFill,
    "clipboard-text-fill": ClipboardTextFill,
    "x-circle-fill": XCircleFill,
    "checks-fill": ChecksFill,
    "generic-file": GenericFile,
    "pdf-file": PdfFile,
    bank: Bank,
    signout: Signout,
    "hand-click": HandClick,
    bpay: BPAY,
    copy: Copy,
    "door-open": DoorOpen,
};
export const IconsProps = {
    "entry-inspection": EntryInspectionProps.properties,
    "routine-inspection": RoutineInspectionProps.properties,
    "exit-inspection": ExitInspectionProps.properties,
    close: CloseProps.properties,
    magnify: MagnifyProps.properties,
    "chevron-down": ChevronDownProps.properties,
    check: CheckProps.properties,
    "check-circle": CheckCircleProps.properties,
    "check-circle-outline": CheckCircleOutlineProps.properties,
    "check-box-blank-square": CheckBoxBlankSquareProps.properties,
    "check-box-square": CheckBoxSquareProps.properties,
    "account-alert": AccountAlertProps.properties,
    "chevron-right": ChevronRightProps.properties,
    "account-clock": AccountClockProps.properties,
    eye: EyeProps.properties,
    "clock-outline": ClockOutlineProps.properties,
    map: MapProps.properties,
    minus: MinusProps.properties,
    "file-document": FileDocumentProps.properties,
    camera: CameraProps.properties,
    calendar: CalendarProps.properties,
    "calendar-blank": CalendarBlankProps.properties,
    "calendar-blank-fill": CalendarBlankFillProps.properties,
    "calendar-edit": CalendarEditProps.properties,
    drag: DragProps.properties,
    "chevron-up": ChevronUpProps.properties,
    "folder-key": FolderKeyProps.properties,
    information: InformationProps.properties,
    phone: PhoneProps.properties,
    "block-helper": BlockHelperProps.properties,
    sync: SyncProps.properties,
    "progress-clock": ProgressClockProps.properties,
    "arrow-left": ArrowLeftProps.properties,
    "home-account": HomeAccountProps.properties,
    "comment-text-multiple": CommentTextMultipleProps.properties,
    "wifi-off": WifiOffProps.properties,
    error: ErrorProps.properties,
    pets: PetsProps.properties,
    flag: FlagProps.properties,
    star: StarProps.properties,
    "shape-circle-plus": ShapeCirclePlusProps.properties,
    "circle-outline": CircleOutlineProps.properties,
    "alert-circle": AlertCircleProps.properties,
    "alert-circle-outline": AlertCircleOutlineProps.properties,
    "ellipsis-v": EllipsisVProps.properties,
    "caret-down": CaretDownProps.properties,
    comment: CommentProps.properties,
    key: KeyProps.properties,
    cancel: CancelProps.properties,
    pencil: PencilProps.properties,
    plus: PlusProps.properties,
    list: ListProps.properties,
    forward: ForwardProps.properties,
    "delete-forever": DeleteForeverProps.properties,
    "chevron-left": ChevronLeftProps.properties,
    loading: LoadingProps.properties,
    "home-import-outline": HomeImportOutlineProps.properties,
    "square-edit-outline": SquareEditOutlineProps.properties,
    upload: UploadProps.properties,
    download: DownloadProps.properties,
    "photo-library": PhotoLibraryProps.properties,
    "map-marker": MapMarkerProps.properties,
    "cash-multiple": CashMultipleProps.properties,
    wrench: WrenchProps.properties,
    cog: CogProps.properties,
    "close-circle": CloseCircleProps.properties,
    "filter-variant": FilterVariantProps.properties,
    pending: PendingProps.properties,
    "sad-face-outline": SadFaceOutlineProps.properties,
    menu: MenuProps.properties,
    "single-photo": SinglePhotoProps.properties,
    "long-arrow-back": LongArrowBackProps.properties,
    "inversed-arrows": InversedArrowsProps.properties,
    "grid-drag": GridDragProps.properties,
    email: EmailProps.properties,
    "auto-renew": AutoRenewProps.properties,
    "chat-text-fill": ChatTextFillProps.properties,
    "image-square-fill": ImageSquareFillProps.properties,
    "minus-circle-fill": MinusCircleFillProps.properties,
    "plus-circle-fill": PlusCircleFillProps.properties,
    "arrows-in-line-vertical-fill": ArrowsInLineVerticalFillProps.properties,
    "arrows-out-line-vertical-fill": ArrowsOutLineVerticalFillProps.properties,
    "trash-fill": TrashFillProps.properties,
    "caret-left": CaretLeftProps.properties,
    archive: ArchiveProps.properties,
    "dots-three-outline": DotsThreeOutlineProps.properties,
    "chats-outline": ChatsOutlineProps.properties,
    chats: ChatsProps.properties,
    "clipboard-outline": ClipboardOutlineProps.properties,
    clipboard: ClipboardProps.properties,
    home: HomeProps.properties,
    "home-outline": HomeOutlineProps.properties,
    "caret-right": CaretRightProps.properties,
    avatar: AvatarProps.properties,
    bedrooms: BedroomsProps.properties,
    bathrooms: BathroomsProps.properties,
    carparks: CarparksProps.properties,
    "dollar-sign": DollarSignProps.properties,
    "warning-circle": WarningCircleProps.properties,
    "list-dashes": ListDashesProps.properties,
    "check-fill": CheckFillProps.properties,
    "no-eye": NoEyeProps.properties,
    hourglass: HourglassProps.properties,
    "hourglass-medium": HourglassMediumProps.properties,
    "arrow-square-out": ArrowSquareOutProps.properties,
    "chat-circle": ChatCircleProps.properties,
    "check-circle-fill": CheckCircleFillProps.properties,
    "clipboard-text-fill": ClipboardTextFillProps.properties,
    "x-circle-fill": XCircleFillProps.properties,
    "checks-fill": ChecksFillProps.properties,
    "requests-active": RequestsActiveProps.properties,
    "requests-default": RequestsDefaultProps.properties,
    "property-active": PropertyActiveProps.properties,
    "property-default": PropertyDefaultProps.properties,
    "payments-active": PaymentsActiveProps.properties,
    "payments-default": PaymentsDefaultProps.properties,
    "statements-active": StatementsActiveProps.properties,
    "statements-default": StatementsDefaultProps.properties,
    "generic-file": GenericFileProps.properties,
    "pdf-file": PdfFileProps.properties,
    bank: BankProps.properties,
    signout: SignoutProps.properties,
    "hand-click": HandClickProps.properties,
    bpay: BPAYProps.properties,
    copy: CopyProps.properties,
    "door-open": DoorOpenProps.properties,
};
export default Icons;
